<template>
  <!-- 广告修改页面 -->
  <moe-page title="活动修改">
    <moe-form ref="activityAmendForm" :model="activityAmend" :rules="activityAmendRules" :showBack="false" :showClose="true">
      <el-form-item label="活动名称" prop="name">
        <el-input v-model.trim="activityAmend.name" placeholder="请输入活动名称" maxlength="50" clearable />
      </el-form-item>

      <el-form-item label="活动位置" prop="alias">
        <moe-select type="activityAliasList" v-model="activityAmend.alias" placeholder="请选择活动位置" clearable></moe-select>
      </el-form-item>

      <el-form-item label="展示风格" prop="style">
        <!-- <moe-select type="activityAliasStyleList" v-model="activityAmend.style.mode" placeholder="请选择展示风格"></moe-select> -->
        <el-input v-model="activityAmend.style" placeholder="请输入展示风格" clearable></el-input>
      </el-form-item>

      <el-form-item label="最多展示商品数量" prop="goodsNum">
        <el-input :value="activityAmend.goodsNum" @input="(value) => activityAmend.goodsNum = $moe_formatter.formatterInteger(value)" placeholder="请输入最多展示商品数量" maxlength="10" clearable></el-input>
      </el-form-item>

      <el-form-item label="排序" prop="sort">
        <el-input :value="activityAmend.sort" @input="(value) => activityAmend.sort = $moe_formatter.formatterSort(value)" placeholder="请输入活动排序，数值越大排序越靠前" maxlength="50" clearable />
      </el-form-item>

      <el-form-item label="封面图片" prop="coverUrl">
        <moe-upload-file
          v-model="activityAmend.coverUrl"
          ossKey="SHOP_MEDIA"
          upload-text="上传封面图片"
          :default-file-list="coverUrlList"
          @change="() => $refs.activityAmendForm.validateField('coverUrl')" />
      </el-form-item>

      <el-form-item label="活动介绍" prop="content">
        <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4 }" v-model.trim="activityAmend.content" placeholder="输入活动介绍" maxlength="50" clearable/>
      </el-form-item>

      <el-form-item label="展示时间">
        <el-date-picker
          style="width: 100%;"
          placement="bottom-start"
          v-model="datetime"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          @change="(datetime) => ([activityAmend.startTime, activityAmend.endTime] = datetime || ['', ''])" />
          <div class="color-danger">（活动展示时间，时间结束活动自动关闭，如未选时间则一直存在，需手动关闭）</div>
      </el-form-item>

      <template slot="tool">
        <el-button type="primary" icon="el-icon-upload2" :loading="activityAmendLoad" @click="activityAmendInfoSubmit">{{ activityAmendLoad ? '提交中' : '提交信息' }}</el-button>
      </template>
    </moe-form>
  </moe-page>
</template>

<script>
export default {
  name: 'ActivityAmend',
  data() {
    //账号信息
    const activityAmend = {
      id: "",
      name: '',
      alias: '',
      style: '{ "mode": "default" }',
      content: '',
      sort: '',
      coverUrl: '',
      goodsNum: '',
      startTime: '',
      endTime: '',
    };

    //账号数据校验
    const verify = this.$moe_verify.verifyForm;
    const checkGoodsNum = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(`请输入最多展示商品数量`));
      } else {
        if (this.$moe_math.mathLessThanOrEqualTo(value, 0)) {
          return callback(new Error(`最多展示商品数量必须大于0`));
        } else {
          callback();
        }
      }
    }
    const checkStyle = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(`请输入展示风格`));
      } else {
        try {
          let isObject = JSON.parse(value);
          if (typeof (isObject) === 'object') {
            callback();
          } else {
            callback(new Error(`数据格式不正确`));
          }
        } catch {
          callback(new Error(`数据格式不正确`));
        }
      }
    }
    const activityAmendRules = {
      name: verify.isEmpty('请输入活动名称'),
      alias: verify.isEmpty('请选择活动位置'),
      goodsNum: [{ required: true, validator: checkGoodsNum, trigger: ['blur', 'change'] }],
      display: verify.isEmpty('请选择活动是否展示'),
      [`style.mode`]: verify.isEmpty('请选择展示风格')
      // style: [{ required: true, validator: checkStyle, trigger: ['blur', 'change'] }],
    };

    return {
      activityAmend, //活动信息
      coverUrlList: [],
      datetime: [],
      activityAmendRules, //账号数据校验
      activityAmendLoad: false, //提交状态
    };
  },
  created() {
    //获取广告信息
    this.getActivityAmendDetail();
  },
  methods: {
    /**
     * 获取账号详情
     **/
    getActivityAmendDetail() {
      if (this.$route.query.id) {
        this.$moe_api.GOODS_API.activityAmendDetail({ id: this.$route.query.id }).then((data) => {
          if (data.code == 200) {
            let { id, name, alias, style, content, sort, coverUrl, goodsNum, startTime, endTime } = data.result;
            this.activityAmend = {
              id,
              name,
              alias,
              style: style ? style : '{ "mode": "default" }',
              content,
              sort,
              coverUrl: coverUrl ? this.$moe_yunHelper.formatterImageUrl(coverUrl) : '',
              goodsNum: String(goodsNum),
              startTime,
              endTime,
            };

            if (coverUrl) {
              this.coverUrlList = [
                {
                  url: coverUrl,
                  tempFilePath: this.$moe_yunHelper.getTempFilePath(coverUrl),
                }
              ];
            }

            if (startTime) {
              this.datetime = [startTime, endTime];
            }
          } else {
            this.$moe_msg.error(data.message);
          }
        });
      }
    },

    /**
     * 提交账号信息
     **/
    activityAmendInfoSubmit() {
      // 表单校验
      this.$refs['activityAmendForm'].validate(() => {
        this.activityAmendLoad = true;
        // 调用账号修改API
        this.$moe_api.GOODS_API.activityAmend({
          ...this.activityAmend,
          sort: this.activityAmend.sort || 0,
        }).then((data) => {
          if (data.code == 200) {
            this.$moe_msg.success('修改成功', {
              completionHandler: () => {
                this.$moe_coordinator.navigateBack();
              }
            });
          } else {
            this.$moe_msg.error(data.message);
          }
        }).finally(() => {
          this.activityAmendLoad = false;
        });
      });
    },
  },
};
</script>
